import React, { useRef, useEffect } from "react";
import CustomButton from "./CustomButton";

import lottie from "lottie-web";
import failAnim from "../assets/fail.json";

const FailPage = ({ handleGetRoomChargesRetry, setCurrentPage, errorMsg }) => {
  const animRef = useRef(null);

  //console.log(errorMsg);

  useEffect(() => {
    lottie.loadAnimation({
      container: animRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: failAnim,
    });
  }, []);
  return (
    <>
      <div className="page">
        <div ref={animRef} className="lottie-anim" />

        <p>Payment failed</p>
        <p className="grey-text">{errorMsg}</p>
      </div>
      <div className="footer">
        <CustomButton
          onClick={() => {
            setCurrentPage(1);
            handleGetRoomChargesRetry();
          }}
          text=""
        />
      </div>
    </>
  );
};

export default FailPage;
