import React, { useState } from "react";

import Search from "./searchPage/Search";
import Table from "./searchPage/Table";

const SearchPage = ({
  db,
  loading,
  handleGetSearchData,
  handleGetRoomCharges,
  setCurrentPage,
  searchValue,
  setSearchValue,
}) => {
  console.log(db, "search pge");
  return (
    <>
      <Search
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        loading={loading}
        handleGetSearchData={handleGetSearchData}
      />
      <Table
        handleGetRoomCharges={handleGetRoomCharges}
        setCurrentPage={setCurrentPage}
        
          db={db.filter(
            (item) =>
              item?.account_id
                .toLowerCase()
                .indexOf(searchValue.toLocaleLowerCase()) > -1 ||
              item.guest_name
                .toLowerCase()
                .indexOf(searchValue.toLocaleLowerCase()) > -1 ||
              item.reservation_id
                .toLowerCase()
                .indexOf(searchValue.toLocaleLowerCase()) > -1
          )}

      />
    </>
  );
};

export default SearchPage;
