import React from "react";

const CustomButton = ({ onClick, text }) => {
  return (
    //2022-03-04 -- remove retry
    
    //<div className="custom-button">
    <div className="custom-button" onClick={onClick}>
      {text}
    </div>
  );
};

export default CustomButton;
